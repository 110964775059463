import React, { useEffect, useState } from "react";
import { constore } from "../utils/mixins/mixin";

function AppServicesList(props) {
  const [services, setServices] = useState(props.$node?.server?.props?.data?.services || []);

  const getService = (name) => props.getcontent('home.services' + (name ? `.${name}` : ''));

  return (
    <section className="bg-lighter">
      <div className="container pb-40">
        <div className="pt-10" style={{ paddingBottom: '10px', marginBottom: '-10px' }}>
          <h1 className="text-center text-uppercase line-height-1 mb-2">
            <span style={{ fontSize: 'xx-large', fontWeight: '600', fontFamily: 'Geneva, Verdana, sans-serif' }}>{getService('title')}</span>
          </h1>
        </div>
        <div className="section-content text-center" style={{ marginBottom: '4em', marginTop: '1em' }}>
          <div className="row">
            <div className="col-md-12">
              <div className="meet-doctors">
                <p style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px', }}>{getService('description')}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="section-content">
          <div class="row">
            {services.map((service) => (
              <div class="col-sm-6 col-md-4 col-lg-3">
                <div class="service-block bg-white">
                  <div class="thumb">
                    <a
                      href={props.buildpath("/"+service.name)}
                    >
                      <img
                        alt="featured project"
                        src={`/files/secure/image?fileId=${service.image || 1}&asimg=1`}
                        class="img-fluid image-fit-4"
                      />
                    </a>
                    <h4 class="text-white mt-0 mb-0">
                      <span class="price">
                        {service.price} $
                      </span>
                    </h4>
                  </div>

                  <div className="content text-left flip p-25 pt-0">
                    <a
                      href={props.buildpath("/" + service.name)}
                    >
                      <h4 className="line-bottom mb-10" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}> {service.title}</h4>
                    </a>
                    <p style={{ textAlign: 'justify', fontFamily: 'Geneva, Verdana, sans-serif', fontSize: '13px' }}>{service.tag_description}</p>
                    <a
                      className="btn btn-dark btn-theme-colored btn-sm text-uppercase"
                      href={props.buildpath("/" + service.name)}
                    >
                      {getService('view_details_label')}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default constore(AppServicesList, "AppServicesList");
