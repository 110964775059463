import React, { useState } from 'react';

const AppUploadPhotosTitleLabel = () => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const hoverColor = '#6b103e'; 
    return (
        <div className='hover'>
            <div
                className="pt-10"
                style={{
                    backgroundColor: isHovered ? hoverColor : 'rgb(179, 179, 204)',
                    paddingBottom: '5px',
                    transition: 'background-color 0.3s ease',
                    boxShadow: isHovered ? '0 0 10px rgba(0, 0, 0, 0.2)' : 'none', 
                }}
            >
                <h3 className="text-center mt-0 line-height-1">
                    <span className="" style={{ color: '' }}>
                        <div
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={{ transition: 'color 0.3s ease', color: isHovered ? 'white' : 'black' }}
                        >
                            <a href="/uploadphotos" className="band h3 text-bold text-white">
                                Upload your Sample Images and Get them Retouched for free!!
                            </a>
                        </div>
                    </span>
                </h3>
            </div>
        </div>
    );
}

export default AppUploadPhotosTitleLabel;
