import { useEffect } from "react";
import { useState } from "react";
import React from 'react'
import { constore } from '../../utils/mixins/mixin';


const handleSubmit = (e, props) => {
    e.preventDefault();
    let data = e.target.getData();
    props.services.apiCurd("Frontend", {
        action: "register",
        browser_fingerprint: props.services.getBrowserFingerprint().hash(),
        ...data,
        enquiry_type: "upload_form",
    }).then(({ data }) => {
        e.target.reset();
        // if(localStorage.getItem("redirect_path")) {
        //             location.href = localStorage.getItem("redirect_path");
        //         } else {
        //             location.href = props.buildpath("/");
        //         }
        alert("Thank You for Registering with us!");
    });
}

const AppUploadPhotosTitleLabelDetails = (props) => {
    const content = props?.server?.props?.contentdata;
    const getContent = (name) => props.getcontent('home.uploadphotos' + (name ? `.${name}` : ''));
    const country_code = props.getProps("country.code");
    const [phoneCode, setPhoneCode] = useState(props.getProps("country.phone_code"));
    const userName = props.server?.user?.name;
    const userEmail = props.server?.user?.email;
    const isMobile = document.body.clientWidth <= 900; 

    const handleCountryChange = (value, event, { option }) => {
        setPhoneCode(option.phone_code);
    };

    const onOptionsTransform = (data) => {
        let countryName = props.getProps("country.name") || "";
        data = data.map(item=>{
            item.label = item.label
            .split("{incountry}").join(`${countryName}`)
            .split("{country}").join(`in ${countryName}`)
            return item;
        });
        console.log("onOptionsTransform",{data,countryName})
        return data;
    }

    useEffect(() => {
        props.store('request.searchable', 1);
        props.store('request.country_code', country_code);
    }, []);


    return (
        <>

            <div className="container">
                <h1 className="text-center text-theme-color-1 pt-50" style={{ color: '#e79951' }}>{getContent('titlelabeldetails.title')}</h1>

                <p className="font-20" style={{ textAlign: 'center' }}><strong>{getContent('details.tagdesc')}</strong></p>
            </div>

            <div className="zozo-parallax-header"><div className="parallax-header"><h2 className="parallax-title text-center">{getContent('details_subtitle_0_desc')}</h2></div></div>
            <div className="wpb_text_column wpb_content_element pb-50 " >
                <div className="wpb_wrapper">
                    <p style={{ textAlign: 'center', fontSize: '20px' }}>{getContent('details_subtitle_1_desc')}</p>
                </div>
            </div>


            <section id="contact" className="divider layer-overlay overlay-theme-colored-9" data-bg-img="" >
                <div className="container pb-30">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-md-4" data-wow-duration="1.4s">
                                <h2 style={{ textAlign: 'left' }}><strong className="text-white">{getContent('details.form.sidepanel_title')}</strong></h2><br />
                                <div className="text-white">
                                    <ul>
                                        <li className="font-18" style={{ textAlign: 'left' }}><strong className="text-white font-18">Step 1 &#8211; </strong >{getContent('details.form.sidepanel.title_0_step')}</li><br />
                                        <li className="text-white font-18" style={{ textAlign: 'left' }}><strong className="font-18 text-white">Step 2</strong> &#8211; {getContent('details.form.sidepanel.title_1_step')}</li><br />
                                        <li className="text-white font-18" style={{ textAlign: 'left' }}><strong className="text-white">Step 3</strong> &#8211; {getContent('details.form.sidepanel.title_2_step')}</li><br />
                                    </ul>
                                    <h2 style={{ textAlign: 'left' }}><strong className="text-white">{getContent('details.form.sidepanel.payment_title')}</strong></h2>
                                    <p style={{ textAlign: 'left' }}>{getContent('details.form.sidepanel.payment.title_desc')}</p>
                                    <h3 style={{ textAlign: 'left' }}><strong className="text-white">{getContent('details.form.sidepanel.turnaroundtime_title')}</strong></h3>
                                    <p className="pb-30" style={{ textAlign: 'left' }}>{getContent('details.form.sidepanel.turnaroundtime.title_desc')}</p>
                                    <p className="">{getContent('details.form.sidepanel.turnaroundtime.title_finaldesc')}</p>
                                </div>
                            </div>
                            <div className="col-md-8 wow fadeInRight mt-10" data-wow-duration="1.4s">
                                <h3 className="title  line-height-1 text-white mt-0 mb-30"><span className="text-theme-color-1" style={{ color: 'orangered' }}>{getContent('details.form_title')}</span></h3>


                                <form id="reservation_form" name="reservation_form" className="reservation-form file-uploader" onSubmit={(e) => handleSubmit(e, props)} >

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group  mb-10 ">
                                                <AppInput name="name" placeholder="Enter Name*" required={true} defaultValue={userName} ></AppInput>
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="form-group mb-12">
                                                    <AppInput label="Email*" type="email" name="email" placeholder="Enter Your Email Address " required={true} defaultValue={userEmail} ></AppInput>
                                                </div>
                                            </div>

                                        <div className="col-sm-6">
                                            <AppInput
                                                type="select"
                                                choose="Country"
                                                refers="countries"
                                                referselect="distinct code as key,name as label,phone_code"
                                                name="country"
                                                placeholder="Enter Country"
                                                defaultValue={country_code}
                                                onChange={(...args) => handleCountryChange(...args)}
                                                storekey="request.country_code"
                                                required={true}
                                            />
                                        </div>

                                        {/* <div className="col-sm-6">
                                            <AppInput
                                                type="select"
                                                choose="State"
                                                name="state"
                                                referselect="distinct code as key,name as label"
                                                placeholder="Enter State"
                                                query={{
                                                    country_code: "request.country_code"
                                                }}
                                                refers="states"
                                                storekey="request.state_code"
                                                required={true}
                                            >
                                            </AppInput>
                                        </div> */}



                                        <div className="col-sm-6">
                                            <AppInput
                                                type="select"
                                                choose="City"
                                                name="city"
                                                placeholder="Enter City"
                                                query={{
                                                    country_code: "request.country_code",
                                                    searchable: "request.searchable"
                                                }}
                                                refers="cities"
                                                storekey="request.city"
                                                required={true}
                                            >
                                            </AppInput>
                                        </div>


                                        <div className="col-sm-12">
                                            <AppInput
                                                type="text"
                                                label="Phone Number*"
                                                name="phone"
                                                pattern="\+\d{1,3}\d{7,10}"
                                                placeholder="Enter Phone No."
                                                invalidMessage={"Please enter valid mobile number"}
                                                {...(phoneCode ? { defaultValue: '+' + phoneCode } : {})}
                                                required>
                                            </AppInput>
                                        </div>

                                        <div className="col-sm-12">
                                            <AppInput
                                                type="select"
                                                choose="Service"
                                                name="service_id"
                                                refers="services"
                                                referselect="id as key,title as label"
                                                placeholder="Select Service"
                                                onOptionsTransform={onOptionsTransform}
                                                required={true}
                                            >
                                            </AppInput>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="form-group mb-10 ">
                                                <AppInput name="company_name" placeholder="Enter Company Name" required></AppInput>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group mb-10 ">
                                                <AppInput name="company_website" placeholder="Enter Company Website" required></AppInput>
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="form-group mb-10">
                                                <AppInput
                                                    type="textarea"
                                                    name="message"
                                                    data-maxlen="200"
                                                    placeholder="Enter Message(200 words limit)"
                                                    required={true}
                                                >
                                                </AppInput>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group mb-10 text-white">
                                                <AppInput type="file" name="service_file_doc" multiple={true} required={true} placeholder="Please Choose File"></AppInput>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <AppReCaptcha />
                                        </div>
                                        <div className="col-sm-12 pt-30">
                                            <div className="form-group mb-0 text-center">
                                                <button
                                                    type="submit"
                                                    data-loading-text="Please wait..."
                                                    className="btn btn-theme-colored  text-orange  enquire-now-button">
                                                    {getContent('details.form.register_label')}
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>


            </section>

            <section className="bg animatedParent animateOnce pb-0 mob-hide">
                <div className="container">
                    <h3 className="text-center pb-20 text-uppercase text-bold text-theme-colored font-28" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                        HOW IT WORKS?
                    </h3>
                    <img src={`/files/secure/How-it-works.jpg?fileId=246&rd=93&asimg=1&mobile=${isMobile ? '1' : '0'}`}/>
                </div>
            </section>
        </>
    )
}

export default constore(AppUploadPhotosTitleLabelDetails);

