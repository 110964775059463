import { useState } from "react";
import { constore } from "../../utils/mixins/mixin";
import AppStripe from "../stripe/AppStripe";


const applayCoupon = (props, node, event) => {
  let input = event.target.parentElement.querySelector("[name='coupon_code']");
  let value = input.value;
  if (value) {
    props.services.apiCurd("Frontend", {
      tablename: "coupons",
      action: "list",
      name: value,
      other_request: {
        Frontend: {
          action: "validate_coupon",
          name: value
        },
      }
    }).then(({ data, other_request }) => {
      console.log({data, other_request});
      let { checkouts, setDiscount, setCoupon } = node;

      if (data.length) {
        let first = data.find(o => true);
        if(first.type == "value") {
          setDiscount(first.value);
        } else {
          setDiscount(Math.round(node.subtotal * (first.value/100)  * 100 ) / 100 );
        }
        setCoupon(first);
      } else {
        setDiscount(0);
        setCoupon(null);
      }
    }).catch(err => {
      let { checkouts, setDiscount, setCoupon } = node;
      setDiscount(0);
      setCoupon(null);
    });
  } else {
    alert("Please provide valid coupon code");
  }
}

const updateQuantity = (props, node, item, e) => {
  console.log({ e }, 'updateQuantity');
  alert('Input value changed!');
}

const updateCart = (props, node, item, event) => {
  let input = event.target.parentElement.querySelector("[name='quantity']");
  let value = input.value;
  props.services.apiCurd("Frontend", {
    tablename: "checkouts",
    action: "update",
    id: item.id,
    qty: value
  }).then(({ data }) => {
    window.location.reload();
    
  }).catch(err => {
    input.val(item.qty);
  });
}

const proceedCheckout = (props, node) => {
  if (props.server.user) {
    props.services.apiCurd("Frontend", {
      action: "createorder",
      browser_fingerprint: props.services.getBrowserFingerprint().hash(),
      total: node.total,
      ...(node?.coupon?.id ? { coupon_id: node?.coupon?.id } : {})
    }).then(({ data }) => {
      window.location.reload();
    }).catch(err => {

    });
  } else {
    props.$onTrigger("userLoggedIn", () => {
      window.location.reload();
    });
    props.$trigger("loginModel");
  }
}

const updateServiceFiles = (props, node, e) => {
  e.preventDefault();
  let data = e.target.getData();
  console.log({ data });
  if (props.server.user) {
    props.services.apiCurd("Frontend", {
      action: "updateorderfiles",
      browser_fingerprint: props.services.getBrowserFingerprint().hash(),
      ...data
    }).then(({ data }) => {
      console.log({data});
      if(data.payment_status == "success") {
        alert("Your Order Placed Successfully");
        window.location.href = "/customer-details";
      } else {
        fetchCartItems(props, node);
      }
    }).catch(err => {

    });
  } else {
    props.$emit.trigger("loginModel");
  }
}

const removeItem = (props, node, item) => {
  props.services.apiCurd("Frontend", {
    tablename: "checkouts",
    action: "delete",
    id: item.id
  }).then(({ data }) => {
    window.location.reload();
  });
}

const openAsPopup = (url,props) => {
  var width = screen.width/2; // Define the width of the popup
  var height = screen.height/2; // Define the height of the popup
  var left = (screen.width - width) / 2; // Center the popup
  var top = (screen.height - height) / 4; // Center the popup

  // Properties for the popup
  var params = 'width=' + width + ', height=' + height;
  params += ', top=' + top + ', left=' + left;
  params += ', directories=no';
  params += ', location=no';
  params += ', menubar=no';
  params += ', resizable=no';
  params += ', scrollbars=no';
  params += ', status=no';
  params += ', toolbar=no';

  // Open the popup
  let newWindow = window.open(url, 'Popup', params);
  console.log({newWindow});
  const interval = setInterval(() => {
      if (newWindow.closed) {
          clearInterval(interval);
          // window.location.reload();
          window.location.href = props.buildpath("/");
      }
  }, 500);
  if(newWindow.focus) {
    newWindow.focus();
  }
  console.log({newWindow});
}


const initialPayment = (props, node, e) => {
  e.preventDefault();
  let data = e.target.getData();
  if (props.server.user) {
    props.services.apiCurd("Frontend", {
      action: "createpayment",
      browser_fingerprint: props.services.getBrowserFingerprint().hash(),
      ...data
    }).then(({ redirect_link }) => {
      window.location.href = redirect_link;
      // openAsPopup(redirect_link,props);
      // fetchCartItems(props, node);
    }).catch(err => {

    });
  } else {
    props.$emit.trigger("loginModel");
  }
};

const computeAdditionalServices = (additional_services) => {
  if (additional_services && typeof additional_services == "string") {
    return additional_services.parse();
  } else if (additional_services && additional_services instanceof Array) {
    return additional_services;
  } else {
    return [];
  }
}

const computeCheckout = (data,node) => {
  let country_name = node.country_name;
  console.log("computeCheckout", data,node);
  let payload = data.map(item => {
    let additional_services = computeAdditionalServices(item.service.additional_services);
    additional_services = additional_services.map(o=>({
      ...o,
      checked:(item.additional_services||'').split(",").includes(o.name) 
    }));
    let addsrvsum = additional_services.filter(o => o.checked).map(o => o.price*item.qty).sum();
    console.log("computeCheckout additional_services", additional_services, addsrvsum);
    return {
      ...item,

      service: {
        ...item.service,
        title: (item.service.title||"").split("{incountry}").join(""),
        additional_services,
        addsrvsum,
      },
      sum: (item.qty * item.package.price)
    }
  });
  console.log("computeCheckout payload", payload);
  return payload;
}

const fetchCartItems = (props, node) => {
  props.services.apiCurd("Frontend", {
    action: "getcartitems",
    browser_fingerprint: props.services.getBrowserFingerprint().hash()
  }).then(({ data, lastOrder }) => {
    let computedCheckoutItems = computeCheckout(data,node);
    node.setCheckouts(computedCheckoutItems);
    console.log("computedCheckoutItems", computedCheckoutItems);
    node.setOrder(lastOrder);
    console.log({ lastOrder });
    if (data.length) {

    } else if (lastOrder?.service_file) {
      setTimeout(() => {
        document.body.querySelector("#accordion13")
          .parentElement.querySelector("a").click();
      })
    } else if (lastOrder) {
      document.body.querySelector("#accordion14")
        .parentElement.querySelector("a").click();
    }
  }).catch(err => {
    console.log("fetchCartItems",{err});
  });
}

const applyCharges = (props, node, e, addsrvs, item) => {
  let checked = !!e.target.checked;
  let name = e.target.name;
  addsrvs.checked = checked;
  let checkedPayload = item.service.additional_services.filter(o=>o.checked).map(o=>o.name).join(',');
  props.services.apiCurd("Frontend", {
    tablename: "checkouts",
    action: "update",
    id: item.id,
    additional_services: checkedPayload
  }).finally(() => {
    fetchCartItems(props, node);

    //node.setCheckouts(computeCheckout([...node.checkouts]));
  });
}

export default constore(function (props) {
  let [checkouts, setCheckouts] = useState([]);
  let fileurl = "/files/secure/image?fileId={fileId}&asimg=1";
  let subtotal = checkouts.reduce((c, item) => c + (item.sum + item.service.addsrvsum), 0);
  let [discount, setDiscount] = useState(0);
  let [coupon, setCoupon] = useState();
  let [order, setOrder] = useState();
  let [sameAsBilling, setSameAsBilling] = useState(false);
  let [handling_charges, setHandlingChanges] = useState(0);
  let total = subtotal + handling_charges - discount;
  let node = {
    country_name: props.getProps('country.name'),
    checkouts,
    subtotal,
    total,
    discount,
    setCheckouts,
    setDiscount,
    setCoupon,
    coupon,
    setOrder
  };
  useState(() => {
    fetchCartItems(props, node);
  }, []);
  props.$setNode(node);

  const content = props?.server?.props?.contentdata;
  console.log({ content });
  const getContent = (name) => props.getcontent('home.cart' + (name ? `.${name}` : ''));
  return (
    <div>
      <section>
        <div className="container">
          <h2 className="text-center text-bold text-theme-colored pt-50 pb-30" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }} >{getContent('checkout_title')}<span /></h2>
          <div id="accordion1" className="panel-group accordion">
            <div className="panel">
              <div className="panel-title" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}> <a data-parent="#accordion1" data-toggle="collapse" href="#accordion11" className="active" aria-expanded="true"> <span className="open-sub"></span> {getContent('order_summary_title')}</a> </div>
              <div id="accordion11" className="panel-collapse collapse in" aria-expanded="true">
                <div className="panel-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-striped table-bordered tbl-shopping-cart">
                          <thead>
                            <tr>
                              <th style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}></th>
                              <th>{getContent('serviceicon_title')}</th>
                              <th>{getContent('serviname_title')}</th>
                              <th>{getContent('price_title')}/<sub>{getContent('price_per_image_title')}</sub></th>
                              <th>{getContent('quantity_title')}</th>
                              <th>{getContent('total_title')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (checkouts || []).map((item, i) => (<>
                                <tr className="cart_item" key={i + 'mainitem'} >
                                  <td className="product-remove"><a title="Remove this item" onClick={() => removeItem(props, node, item)} className="remove" href="">×</a></td>
                                  <td className="product-thumbnail"><a href=""><img alt="member" src={fileurl.split("{fileId}").join(item.service.image)} /></a></td>
                                  <td className="product-name"><a href=""><strong>{item.service.title}</strong></a>
                                    <ul className="variation">
                                      <li className="variation-size"><strong>{getContent('Services_title')}</strong>: <span className="" style={{ color: "#4CAF50;" }}><b>{getContent('included_title')}</b></span></li>
                                      <ul className="pt-5 pb-5">
                                        {item.package.includes.split(",").map(
                                          value => (
                                            <li>
                                              <input type="checkbox" id="valid" name="valid" value={value} checked />
                                              <label style={{paddingLeft:'5px'}} >{value}</label>
                                            </li>
                                          )
                                        )}

                                      </ul>
                                      {/* <li className="variation-size"><strong>Date</strong>: <span>Dec 01 - Dec 02, 2018 (2 Days)</span></li>
                                      <li className="variation-size"><strong>Time</strong>: <span>09:00 AM - 05:00 PM</span></li>
                                      <li className="variation-size"><strong>Location</strong>: <span>Bangalore, India</span></li> */}
                                    </ul>
                                  </td>
                                  <td className="product-price"><span className="amount">$ {item.package.price}</span></td>
                                  <td className="product-quantity">
                                    <div className="quantity buttons_added">
                                      <input type="button" className="minus" value="-" />
                                      <input type="number"
                                        size="100"
                                        className="input-text qty text"
                                        title="Qty" value={item.qty}
                                        name="quantity"
                                        min="1"
                                        step="1"
                                        onChange={(e) => updateQuantity(props, node, item, e)}
                                      />
                                      <input type="button" className="plus" value="+" />

                                    </div>
                                    <span>{item.qty} X</span><br />
                                    <button type="button" className="btn btn-theme-color-2 text-white" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }} onClick={(e) => updateCart(props, node, item, e)}>
                                      {getContent('update_label')}
                                    </button>
                                  </td>
                                  <td className="product-subtotal"><span className="amount">$ {item.sum}</span></td>
                                </tr>
                                {
                                  // (item.service.additional_services && <tr class="cart_item" key={i + 'additem'}>
                                  //   <td class="product-remove"><a title="Remove this item" class="remove" href="#">×</a></td>
                                  //   <td class="product-thumbnail"><a href="#">
                                  //     {/* <img alt="member" src="images/slider/s3.jpg" /> */}
                                  //   </a></td>
                                  //   <td class="product-name"><a href="#"><strong>Additional Services ( Extra Charges Applied )</strong></a>
                                  //     <ul class="pt-5 pb-5 variation">
                                  //       {
                                  //         item.service.additional_services.map((addsrvs, i) => <li>
                                  //           <input type="checkbox" id={'adsrv-' + i + '-' + addsrvs.name} name={addsrvs.name} onChange={(e) => applyCharges(props, node, e, addsrvs, item)} checked={addsrvs.checked} />
                                  //           <label for={'adsrv-' + i + '-' + addsrvs.name} style={{paddingLeft:'5px',cursor:'pointer'}} >{addsrvs.title}</label>
                                  //         </li>)
                                  //       }
                                  //     </ul>
                                  //   </td>
                                  //   <td class="product-price">
                                  //     <span class="amount">
                                  //       {item.service.additional_services.filter(o => o.checked).map(o => o.price).sum()}
                                  //     </span>
                                  //   </td>
                                  //   <td class="product-quantity"><div class="quantity buttons_added">

                                  //   </div>

                                  //   </td>
                                  //   <td class="product-subtotal">
                                  //     <span class="amount">
                                  //       {item.service.additional_services.filter(o => o.checked).map(o => o.price*item.qty).sum()}
                                  //     </span>
                                  //   </td>
                                  // </tr>) || ""
                                }
                              </>
                              ))
                            }
                            <tr className="cart_item">

                              <td colspan="5">&nbsp;</td>
                              <td>
                                <div className="coupon form-group">
                                  <label for="cart-coupon" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}> {getContent('coupon_label')} </label>
                                  <AppInput name="coupon_code" placeholder="Enter your coupon" />
                                  <button
                                    onClick={(e) => applayCoupon(props, node, e)} type="button" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}
                                    className="btn btn-theme-color-2 text-white"
                                    disabled={!checkouts.length}
                                  >
                                    {getContent('apply_coupon_label')}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="col-md-4">
                        <img src="/storage/ssl.jpeg" alt="Image" />
                      </div>
                      <div className="col-md-8">
                        <p style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('ssl_desc')}</p>
                      </div>
                    </div>

                    <div className="col-md-6" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                      <h4>{getContent('total_label')}</h4>
                      <table className="table table-bordered">
                        <tbody style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                          <tr>
                            <td>{getContent('sub_total_label')}</td>
                            <td><strong>$ {subtotal}</strong></td>
                          </tr>
                          <tr>
                            <td>{getContent('ship_label')}</td>
                            <td>$ {handling_charges}</td>
                          </tr>
                          {
                            (discount && <tr>
                              <td>{getContent('discount_label')}</td>
                              <td>$ {discount}</td>
                            </tr> || '')
                          }
                          <tr style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                            <td><strong>{getContent('ordertotal_label')}</strong></td>
                            <td><strong className="text-theme-color-2">$ {total}</strong></td>
                          </tr>
                        </tbody>
                      </table>
                      <button
                        type="button"
                        className="btn btn-theme-color-2 pull-right"
                        disabled={!checkouts.length}
                        onClick={() => proceedCheckout(props,node)}
                      >
                        <strong className="text-white font-16" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                          {getContent('proceed_label')}
                          <i className=" fa fa-angle-right " style={{ marginLeft: '5px' }} ></i>
                        </strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              ((!order?.service_file) && <div className="panel">
                <div className="panel-title" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}> 
                <a data-parent="#accordion1" data-toggle="collapse" href="#accordion14" className="collapsed" aria-expanded="false"> 
                <span className="open-sub"></span>{getContent('uploadimg_label')}</a> </div>
                <div id="accordion14" className="panel-collapse collapse" aria-expanded="false">
                  <form className="panel-content" method="post" onSubmit={(e) => updateServiceFiles(props, node, e)}>
                    <div className="payment-method" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                      <label> {getContent('zipfile_label')}</label>
                      <AppInput type="file" name="file" placeholder="Select the Images in Zip or Sigle Image" required={true}></AppInput>
                    </div>
                    <div className="flex gap-2" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                      <label> {getContent('billing_label')}</label>
                      <AppInput type="text" label="Address Line 1" name="billing_address_1" placeholder="Ex. House No./ House Name, Streat Name, Area"></AppInput>
                      <AppInput type="text" label="Address Line 2" name="billing_address_2" placeholder="Ex. Nearest Location,City, Pincode/Zip Code"></AppInput>
                    </div>
                    {/* <div>
                      <AppInput type="checkbox" label="Is Same as Billing Address" onChange={(val, e) => setSameAsBilling(e.target.checked)} />
                    </div> */}
                    {/* {!sameAsBilling && <div>
                      <label>Shipping Address</label>
                      <AppInput type="text" label="Address Line 1" name="shipping_address_1" placeholder="Ex. House No./ House Name, Streat Name, Area"></AppInput>
                      <AppInput type="text" label="Address Line 2" name="shipping_address_1" placeholder="Ex. Nearest Location,City, Pincode/Zip Code"></AppInput>
                    </div>} */}
                    <div className="d-flex">
                      <button
                        type="submit"
                        className="btn btn-theme-color-2"
                        style={{ marginLeft: 'auto' }}
                      >
                        <strong className="text-white font-16" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                          {getContent('update_label')}
                          <i className=" fa fa-angle-right " style={{ marginLeft: '5px'}} ></i>
                        </strong>
                      </button>
                    </div>
                  </form>
                </div>
              </div> || '')
            }
            {
              (order?.service_file  &&
                <div className="panel">
                  <div className="panel-title"> <a data-parent="#accordion1" data-toggle="collapse" href="#accordion13" className="collapsed" aria-expanded="false">
                     <span className="open-sub"></span> {getContent('secure_payment_label')}</a> </div>
                  <div id="accordion13" className="panel-collapse collapse" aria-expanded="false">
                    <form className="panel-content" method="post" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }} onSubmit={(e) => initialPayment(props, node, e)}>
                      <h3>{getContent('payment_info_label')}</h3>
                      <div className="payment-method">
                        <div className="radio">
                          <label>
                            <input type="radio" id="payment" name="payment" value="paypal" required style={{ fontFamily: 'Geneva, Verdana, sans-serif' }} />
                            {getContent('paypal_payment_label')} </label>
                          <p>  {getContent('paypal_payment_desc')} </p>
                        </div>
                      </div>
                      <div className="payment-method">
                        <div className="radio">
                          <label>
                            <input type="radio" id="payment" name="payment" value="razorpay" required style={{ fontFamily: 'Geneva, Verdana, sans-serif' }} />
                            {getContent('rupay_payment_label')} </label>
                          <p>  {getContent('rupay_payment_desc')} </p>
                        </div>
                      </div>
                      <div className="payment-method">
                        <div className="radio">
                          <label>
                            <input type="radio" id="payment" name="payment" value="stripepay" required style={{ fontFamily: 'Geneva, Verdana, sans-serif' }} />
                            {getContent('stripe_payment_label')} </label>
                          <p>  {getContent('stripe_payment_desc')} </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 col-sm-3 col-xs-12 pull-right">
                          <button type="submit" className="btn btn-theme-color-2 pull-right" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}><strong className="text-white">{getContent('proceed_to_checkout_label')}</strong></button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> || "")
            }

          </div>
        </div>
      </section>

    </div>)
}, "AppCheckout");