import { constore } from "../utils/mixins/mixin";

export default constore(function (props) {
    const getContent = (name) => props.getcontent('home' + (name ? `.${name}` : ''));
    return (<>
        <section class="mb-40 mt-0">
            <div class="container">
            <h2 class="sub-title-left text-theme-colored text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'center', lineHeight: '1.5', padding: '15px', fontSize: '28px'}}> <span> {getContent('termsandcondition_title')}</span></h2>
                
                <h5 class="text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}> {getContent('termsandcondition_1st_subtitle')}</h5>
                <p class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}> 
                {getContent('termsandcondition_1st_subtitle_desc')}</p>
               
                <h5  class="text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}>{getContent('termsandcondition_2nd_subtitle')} </h5>
                <p class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}> {getContent('termsandcondition_2nd_subtitle_desc')}</p>
               
                <h5 class="text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}> {getContent('termsandcondition_3rd_subtitle')} </h5>
                <p class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}>{getContent('termsandcondition_3rd_subtitle_desc')}</p>
                
                <h5 class= "text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}> {getContent('termsandcondition_4th_subtitle')} </h5>
                <p class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}> {getContent('termsandcondition_4th_subtitle_desc')}</p>
                
                <h5 class="text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}> {getContent('termsandcondition_5th_subtitle')} </h5>
                <p  class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}>  {getContent('termsandcondition_5th_subtitle_desc')}</p>
                
                <h5 class="text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}> {getContent('termsandcondition_6th_subtitle')} </h5>
                <p  class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}> {getContent('termsandcondition_5th_subtitle_desc')}</p>
                
                <h5 class="text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}> {getContent('termsandcondition_7th_subtitle')} </h5>
                <p class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}> {getContent('termsandcondition_7th_subtitle_desc')}</p>
                
                <h5  class="text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}> {getContent('termsandcondition_8th_subtitle')} </h5>
                <p class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}> {getContent('termsandcondition_8th_subtitle_desc')}</p>
                
                <h5  class="text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}> {getContent('termsandcondition_9th_subtitle')} </h5>
                <p class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}> {getContent('termsandcondition_9th_subtitle_desc')}</p>
            </div>
        </section>
    </>)
}, "AppTermAndConditions");