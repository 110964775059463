import { constore } from "../../utils/mixins/mixin";

export default constore(function (props) {
    const getContent = (name) => props.getcontent('home' + (name ? `.${name}` : ''));

    const blockColors = ['#f26679', '#00ced1', '#e0d74c', '#66c8f2'];
    const isMobile = document.body.clientWidth <= 900;

    return (
        // <section className="bg-lighter animatedParent animateOnce pb-50 mob-hide">
        //     <div className="container">
        //         <h3 className="text-center pb-50 text-uppercase text-bold text-theme-colored font-28" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
        //             {getContent('about.services.togetstarted_title')}
        //         </h3>

        //         <div className="clearfix fadeInLeft animated">
        //             {Array.from({ length: 4 }).map((_, index) => (
        //                 <div key={index} className={`col-xs-12 col-sm-12 col-md-3 col-lg-3`} style={{ backgroundColor: blockColors[index] }}>
        //                     <div className="our_process_box pl-20 pr-20 pb-20 pt-40" style={{ marginBottom: '20px' }}>
        //                         <div className="text-center">
        //                             <i className={`fa ${['fa-cloud-upload', 'fa-edit', 'fa-picture-o', 'fa-cloud-download'][index]} d-none d-md-block`} style={{ fontSize: '88px', color: 'white' }}></i>
        //                         </div>
        //                         <p className="font-18 text-center">
        //                             <strong className={`${index % 2 === 0 ? 'upload' : 'edit'}`}>{getContent(`about.services.togetstarted_panel.${index}.title`)}</strong>
        //                         </p>
        //                         <p className="20" style={{ textAlign: 'justify', fontFamily: 'Geneva, Verdana, sans-serif' }}>
        //                             {getContent(`about.services.togetstarted_panel.${index}.desc`)}
        //                         </p>
        //                     </div>
        //                 </div>
        //             ))}
        //         </div>
        //     </div>
        // </section>
        <section className="bg animatedParent animateOnce pb-0 mob-hide">
        <div className="container">
            <h3 className="text-center pb-20 text-uppercase text-bold text-theme-colored font-28" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>
                HOW IT WORKS?
            </h3>
            <img src={`/files/secure/How-it-works.jpg?fileId=246&rd=93&asimg=1&mobile=${isMobile ? '1' : '0'}`}/>
        </div>
    </section>
    );
}, "AppGetStarted");

