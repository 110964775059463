import { constore } from "../utils/mixins/mixin";

function AppFooter(props) {
  const content = props?.server?.props?.contentdata;
  const getContent = (name) => props.getcontent('home' + (name ? `.${name}` : ''));

  return (
    <footer id="footer" className="bg-lighter pt-30 pb-0" style={{ backgroundColor: '#fafafa' }} data-bg-img="https://placehold.jp/080808/080808/1920x1000.png">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <a href={props.buildpath("/")}>                                       
              <img className="mt-0 mb-25 img-rounded mx-auto my-auto" alt="" src="/files/secure/Logo-(3).jpg?fileId=223&rd=68&asimg=1" style={{ width: '210px', height: '60px', borderRadius: '10px' }} />
            </a>
            <div className="widget dark">
              <h4 className="widget-title" style={{ fontFamily: 'Geneva, Verdana, sans-serif', lineHeight: '1.5', fontSize: '16px' }}>{getContent('footer_usefullinks_label')}</h4>
              <ul className="list angle-double-right list-border ">
                <li><a href={'/'+props.buildpath("/about-us",{hasCountry:false})} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '14px' }}>About Us</a></li>
                <li><a href={props.buildpath("/services")} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '14px' }}>Our Services</a></li>
                <li><a href={'/'+props.buildpath("/guestuser",{hasCountry:false})} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '14px' }}>Guest User</a></li>
                <li><a href={'/'+props.buildpath("/blog",{hasCountry:false})} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '14px' }}>Blog</a></li>
              </ul>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 mt-80 mb-10 ">
            <div className="widget dark">
              <h4 className="widget-title" style={{ fontFamily: 'Geneva, Verdana, sans-serif', lineHeight: '1.5', fontSize: '16px' }}>{getContent('footer_quicklinks_label')}</h4>
              <ul className="list angle-double-right list-border">
                <li><a href={'/'+props.buildpath("/uploadphotos",{hasCountry:false})} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '14px' }}>Upload Photos</a></li>
                <li><a href={props.buildpath("/pricing")} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '14px' }}>Pricing</a></li>
                <li><a href={'/'+props.buildpath("/contact-us",{hasCountry:false})} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '14px' }}>Contact Us</a></li>
              </ul>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 mt-80 mb-10 border-bottom">
            <div className="widget dark">
              <h4 className="widget-title" style={{ fontFamily: 'Geneva, Verdana, sans-serif', lineHeight: '1.5', fontSize: '16px' }}>{getContent('footer_address_1_label')}</h4>
              <p style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('footer_address_1_0_value')}</p>
              <ul className="list-inline mt-5">
                <li className="m-0 pl-10 pr-10">
                  <i className="fa fa-phone text-theme-colored mr-5"></i>
                  <a className="text-gray" href="tel:+1 949-281-1168" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('footer_address_1_1_value')}</a>
                </li>
                <li className="m-0 pl-10 pr-10">
                  <i className="fa fa-envelope-o text-theme-colored mr-5"></i>
                  <a className="text-gray" href={`mailto:${getContent('footer_address_1_2_value')}`} style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('footer_address_1_2_value')}</a>
                </li>
                <li className="m-0 pl-10 pr-10 pb-30">
                  <i className="fa fa-globe text-theme-colored mr-5"></i>
                  <a className="text-gray" href="/" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('footer_address_1_3_value')}</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-sm-6 col-md-3 mt-80 mb-10 border-bottom">
            <div className="widget dark">
              <h4 className="widget-title" style={{ fontFamily: 'Geneva, Verdana, sans-serif', lineHeight: '1.5', fontSize: '16px' }}>{getContent('footer_address_2_label')}</h4>
              <p style={{ fontFamily: 'Geneva, Verdana, sans-serif'}}>{getContent('footer_address_2_0_value')}</p>
              <ul className="list-inline mt-5">
                <li className="m-0 pl-10 pr-10">
                  <i className="fa fa-phone text-theme-colored mr-5"></i>
                  <a className="text-gray" href="tel:080-49567257" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('footer_address_2_1_value')}</a>
                </li>
                <li className="m-0 pl-10 pr-10">
                  <i className="fa fa-envelope-o text-theme-colored mr-5"></i>
                  <a className="text-gray" href={`mailto:${getContent('footer_address_2_2_value')}`} style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('footer_address_2_2_value')}</a>
                </li>
                <li className="m-0 pl-10 pr-10">
                  <i className="fa fa-globe text-theme-colored mr-5"></i>
                  <a className="text-gray" href="/" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('footer_address_2_3_value')}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mt-30">
          <div className="col-md-2">
            <div className="widget dark">
              <h5 className="widget-title mb-10" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify' }}>
                {getContent('footer_callusnow_label')}
              </h5>
              <div className="text-gray" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify' }}>
              <i className="fa fa-phone text-theme-colored mr-5"></i>
              <a href={`tel:+1 949-281-1168`}>{getContent('footer_callusnow_values')}</a>
              </div>
                  <i className="fa fa-phone text-theme-colored mr-10"></i>
                  <a className="text-gray" href="tel:080-49567257" style={{ fontFamily: 'Geneva, Verdana, sans-serif' }}>{getContent('footer_address_2_1_value')}</a>
            </div>
            </div>
        
          <div className="col-md-4">
            <div className="widget dark">
              <h5 className="widget-title mb-10" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify' }}>
                {getContent('footer_connectwithus_label')}
              </h5>
              <ul className="styled-icons icon-bordered icon-sm">
                <li><a href="https://www.facebook.com/colourcubz/"><i className="fa fa-facebook text-theme-colored"></i></a></li>
                <li><a href="https://www.linkedin.com/company/colourcubz/"><i className="fa fa-linkedin text-theme-colored"></i></a></li>
                <li><a href="https://www.instagram.com/colourcubz"><i className="fa fa-instagram text-theme-colored"></i></a></li>
                <li><a href=""><i className="fa fa-twitter text-theme-colored"></i></a></li>
              </ul>
            </div>
          </div>

          {/* <div className="text-center  col-md-3">
            <a href="#" onClick={() => window.open('https://www.sitelock.com/verify.php?site=dreamtree.in.net', 'SiteLock', 'width=600,height=600,left=160,top=170;')}>
              <img
                className="img-fluid"
                alt="SiteLock"
                title="SiteLock"
                src="https://shield.sitelock.com/shield/dreamtree.in.net"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  display: 'inline-block',
                }}
              />
            </a>
          </div> */}

          <div className="col-md-6">
            <div className="widget dark">
              <h5 className="widget-title mb-10" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify' }}>
                Subscribe Us
              </h5>
              <form id="mailchimp-subscription-form-footer" className="newsletter-form">
                <div className="input-group">
                  <input type="email" value="" name="EMAIL" placeholder="Your Email" className="form-control input-lg font-16" data-height="45px" id="mce-EMAIL-footer" />
                  <span className="input-group-btn">
                    <button data-height="45px" className="btn bg-theme-colored text-white btn-xs m-0 font-14" type="submit" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', }}>Subscribe</button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
        
        <div className="footer-bottom bg-white">
          <div className="container pt-3 pb-3">
            <div className="row">
              <div className="col-md-4 text-center text-md-center">
                <p className="list-inline sm-text-center mt-5 font-12 " style={{ fontFamily: 'Geneva, Verdana, sans-serif', }}>
                  Copyright © 2024. All Rights Reserved
                </p>
              </div>
              <div className="col-md-8">
                <div className="widget no-border m-0 text-center ">
                  <ul className="list-inline sm-text-center mt-5 font-12">
                    <li>
                      <a href={'/'+props.buildpath("/terms-conditions",{hasCountry:false})} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'right' }}>
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li>|</li>
                    <li>
                      <a href={'/'+props.buildpath("/privacy-policy",{hasCountry:false})} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'right' }}>
                        Privacy Policy
                      </a>
                    </li>
                    <li>|</li>
                    <li>
                      <a href={'/'+props.buildpath("/refund-policy",{hasCountry:false})} style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'right' }}>
                        Refund Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </footer>
  );
}

export default constore(AppFooter);









// {props.buildpath("/home/-/US")}
// {props.buildpath("/home/-/IN")}
















