import { constore } from "../../utils/mixins/mixin";

export default constore(function (props) {
    const getContent = (name) => props.getcontent('home' + (name ? `.${name}` : ''));
    return (<>
        <section class="mb-40 mt-10">
            <div class="container " style={{ padding: '0px'}}>
                <section class="mb-40 mt-10 privacy">
                    <div class="container ">
                    <h4 class= "text-theme-colored text-bold " style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'Center', lineHeight: '1.5', padding: '15px', fontSize: '28px'}}>{getContent('privacyandpolicy_title')} </h4>
                        <p class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}>{getContent('privacyandpolicy_title_desc')} </p>

                        <h4 class="pt-0 text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}> {getContent('privacyandpolicy_1st_subtitle')} </h4>
                        <ul style={{ listStyleType: 'disc', fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px' , paddingLeft:'70px' }}>
                            <li>{getContent('privacyandpolicy_1st_subtitle_0_points')}</li>
                            <li>{getContent('privacyandpolicy_1st_subtitle_1_points')}</li>
                            <li>{getContent('privacyandpolicy_1st_subtitle_2_points')}</li>
                        </ul>
                        
                        <h4 class="pt-0 text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}>{getContent('privacyandpolicy_2nd_subtitle')}</h4>
                        <ul class="" style={{ listStyleType: 'disc', fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px', paddingLeft:'70px'}}>
                            <li>{getContent('privacyandpolicy_2nd_subtitle_0_points')}</li>
                            <li>{getContent('privacyandpolicy_2nd_subtitle_1_points')}</li>
                            <li>{getContent('privacyandpolicy_2nd_subtitle_2_points')}</li>
                            <li>{getContent('privacyandpolicy_2nd_subtitle_3_points')}</li>
                            <li>{getContent('privacyandpolicy_2nd_subtitle_4_points')}</li>
                            <li>{getContent('privacyandpolicy_2nd_subtitle_5_points')}</li>
                        </ul>
                       
                        <h4 class="pt-0 text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}>{getContent('privacyandpolicy_3rd_subtitle')}</h4>
                        <ul class="" style={{ listStyleType: 'disc',fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px', paddingLeft:'70px'}}>
                            <li>{getContent('privacyandpolicy_3rd_subtitle_0_points')}</li>
                            <li>{getContent('privacyandpolicy_3rd_subtitle_1_points')}</li>
                            <li>{getContent('privacyandpolicy_3rd_subtitle_2_points')}</li>
                            <li>{getContent('privacyandpolicy_3rd_subtitle_3_points')}</li>
                        </ul>
                        
                        <h4 class="pt-0 text-bold" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '22px'}}>{getContent('privacyandpolicy_4th_subtitle')}</h4>
                        <ul class="" style={{ listStyleType: 'disc',fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px', paddingLeft:'70px'}}>
                            <li>{getContent('privacyandpolicy_4th_subtitle_0_points')}</li>
                            <li>{getContent('privacyandpolicy_4th_subtitle_1_points')}</li>
                            <li>{getContent('privacyandpolicy_4th_subtitle_2_points')}</li>
                            <li>{getContent('privacyandpolicy_4th_subtitle_3_points')}</li>
                            <li>{getContent('privacyandpolicy_4th_subtitle_4_points')}</li>
                            <li>{getContent('privacyandpolicy_4th_subtitle_5_points')}</li>
                        </ul>
                        <p class="pt-0" style={{ fontFamily: 'Geneva, Verdana, sans-serif', textAlign: 'justify', lineHeight: '1.5', padding: '15px', fontSize: '16px'}}>{getContent('privacyandpolicy_4th_subtitle_desc')} </p>
                    </div>
                </section>
            </div>
        </section>
    </>)
}, "AppPrivacyAndPolicy1");