import { useEffect } from "react";
import { useState } from "react";
import { constore } from "../utils/mixins/mixin";
import AppInput from "./partials/AppInput";

const handleSubmit = (e, props) => {
    e.preventDefault();
    let data = e.target.getData();
    props.services.apiCurd("Frontend", {
        action: "register",
        browser_fingerprint: props.services.getBrowserFingerprint().hash(),
        ...data,
        ...(props.payload || {}),
        enquiry_type: props.payload?.enquiry_type || "register"
    }).then(({ data }) => {
        e.target.reset();
        if (props.page == "registrationform") {
            alert(<div>
                <div style={{ marginBottom: '10px' }}>
                    Your account has been created, and your credentials have been mailed to the respective address. Please login to proceed further.
                </div>
                <a href={props.buildpath("/login")} style={{ color: 'blue', display: 'block', textAlign:'center'}}>
                    Click here for login now...
                </a>

            </div>);
        } else {
            alert("Enquiry Sent Successfully");
        }
    });
}

export default constore(function (props) {
    const formTitle = props.title || "Make a Registration Now!";
    const getContent = (name) => props.getcontent('home.registraion' + (name ? `.${name}` : ''));
    const country_code = props.getProps("country.code");
    const [phoneCode, setPhoneCode] = useState(props.getProps("country.phone_code"));
    const userName = props.server?.user?.name;
    const userEmail = props.server?.user?.email;



    const handleCountryChange = (value, event, { option }) => {
        setPhoneCode(option.phone_code);
    };

    const onOptionsTransform = (data) => {
        let countryName = props.getProps("country.name") || "";
        data = data.map(item=>{
            item.label = item.label
            .split("{incountry}").join(`${countryName}`)
            .split("{country}").join(`in ${countryName}`)
            return item;
        });
        console.log("onOptionsTransform",{data,countryName})
        return data;
    }

    useEffect(() => {
        props.store('request.searchable', 1);
        props.store('request.country_code', country_code);
    }, []);

    return (<div>
        <section
            id="contact" className="divider bg-3 pb-50"
            style={{ backgroundColor: 'rgb(179, 179, 204)', paddingBottom: '5px' }}>
            <div className="container pb-0">
                <div className="section-content">
                    <div className="row">
                        <div className="col-md-4">
                            <img className="img-fluid" src={getContent('image')} alt={getContent('image_alt_text')} style={{ width: '100%' }} />
                        </div>

                        <div
                            className="col-md-8 mt-10">
                            <h3 className="form-p mb-20">
                                <span className="text-white" style={{ textAlign: 'justify', fontFamily: 'Geneva, Verdana, sans-serif' }}>{formTitle}</span>
                            </h3>
                            <div className="p-5 bg-3
                            
                            ">
                                <form id="reservation_form" name="reservation_form" method="post" onSubmit={(e) => handleSubmit(e, props)} >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <AppInput name="name" placeholder="Enter Name*" required={true} defaultValue={userName} ></AppInput>
                                        </div>
                                        <div className="col-sm-12">
                                        <AppInput label="Email*" type="email" name="email" placeholder="Enter Your Email Address " required={true} defaultValue={userEmail} ></AppInput>
                                        </div>
                                        <div className="col-sm-6">
                                            <AppInput
                                                type="select"
                                                choose="Country"
                                                refers="countries"
                                                referselect="distinct code as key,name as label,phone_code"
                                                name="country"
                                                placeholder="Enter Country"
                                                defaultValue={country_code}
                                                onChange={(...args) => handleCountryChange(...args)}
                                                storekey="request.country_code"
                                                required={true}
                                            />
                                        </div>

                                        {/* <div className="col-sm-6">
                                            <AppInput
                                                type="select"
                                                choose="State"
                                                name="state"
                                                referselect="distinct code as key,name as label"
                                                placeholder="Enter State"
                                                query={{
                                                    country_code: "request.country_code"
                                                }}
                                                refers="states"
                                                storekey="request.state_code"
                                                required={true}
                                            >
                                            </AppInput>
                                        </div> */}
                                        <div className="col-sm-6">
                                            <AppInput
                                                type="select"
                                                choose="City"
                                                name="city"
                                                placeholder="Enter City"
                                                query={{
                                                    country_code: "request.country_code",
                                                    searchable: "request.searchable"
                                                }}
                                                refers="cities"
                                                storekey="request.city"
                                                required={true}
                                            >
                                            </AppInput>
                                        </div>
                                        <div className="col-sm-12">
                                            <AppInput
                                                type="text"
                                                label="Phone Number*"
                                                name="phone"
                                                pattern="\+\d{1,3}\d{7,10}"
                                                placeholder="Enter Phone No."
                                                invalidMessage={"Please enter valid mobile number"}
                                                {...(phoneCode ? { defaultValue: '+' + phoneCode } : {})}
                                                required>
                                            </AppInput>
                                        </div>
                                        <div className="col-sm-12">
                                            <AppInput
                                                type="select"
                                                choose="Service"
                                                name="service_id"
                                                refers="services"
                                                referselect="id as key,title as label"
                                                placeholder="Select Service"
                                                onOptionsTransform={onOptionsTransform}
                                                required={true}
                                            >
                                            </AppInput>
                                        </div>

                                        <div className="col-sm-6">
                                            <AppInput name="company_name" placeholder="Enter Company Name" required></AppInput>
                                        </div>
                                        <div className="col-sm-6">
                                            <AppInput name="company_website" placeholder="Enter Company Website" required></AppInput>
                                        </div>
                                        {
                                            props.needRequest && <div className="col-sm-12">
                                                <AppInput
                                                    type="textarea"
                                                    name="message"
                                                    data-maxlen="200"
                                                    placeholder="Enter Message(200 words limit)"
                                                    required={true}
                                                >
                                                </AppInput>
                                            </div>
                                        }
                                        <div className="col-sm-12">
                                            <div className="form-group mb-10 text-white">
                                                <AppInput type="file" name="service_file_doc" placeholder="Please Choose File" ></AppInput>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <AppReCaptcha />
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group mb-0 mt-50">
                                                <button
                                                    type="submit"
                                                    data-loading-text="Please wait..."
                                                    className="btn btn-theme-colored  text-orange  enquire-now-button">
                                                    Submit Request
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>)
}, "AppRegistrationForm");
